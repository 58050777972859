/** @format */

// For some reason, the default scroll restoration behavior in Gatsby is not working and
// the page is not scrolled to the top when navigating between pages. This is a workaround
// to force the page to scroll to the top when navigating between pages.
exports.shouldUpdateScroll = () => {
  window.scrollTo({ top: 0, behavior: "instant" })
  return false
}

exports.onInitialClientRender = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const utmParams = {
    utm_source: urlParams.get("utm_source") || "",
    utm_medium: urlParams.get("utm_medium") || "",
    utm_campaign: urlParams.get("utm_campaign") || "",
    utm_term: urlParams.get("utm_term") || "",
    utm_content: urlParams.get("utm_content") || "",
    gclid: urlParams.get("gclid") || "",
    hsa_acc: urlParams.get("hsa_acc") || "",
    hsa_cam: urlParams.get("hsa_cam") || "",
    hsa_grp: urlParams.get("hsa_grp") || "",
    hsa_ad: urlParams.get("hsa_ad") || "",
    hsa_src: urlParams.get("hsa_src") || "",
    hsa_tgt: urlParams.get("hsa_tgt") || "",
    hsa_kw: urlParams.get("hsa_kw") || "",
    hsa_mt: urlParams.get("hsa_mt") || "",
    hsa_net: urlParams.get("hsa_net") || "",
    hsa_ver: urlParams.get("hsa_ver") || "",
  }

  sessionStorage.setItem("utmParams", JSON.stringify(utmParams))
}
